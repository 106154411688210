import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import imgProfile from "../../../images/img-profile.png";
import brandLogo from "../../../images/brand-logo.svg";
import icMenu from "../../../images/ic-menu.svg";
import logoutIcon from "../../../images/logout-icon.svg";
import passsword from "../../../images/password.svg";
import profileIcon from "../../../images/ic-user.svg";
import "./styles.scss";
import { useDispatch } from "react-redux";
import { setAlert, setLoading } from "../../../store/actioncreator";
import { Button } from "@mui/material";

const Header = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isRerenderHeader } = props;
  const location = useLocation();
  const pathname = location?.pathname?.split("/")[1];

  const [menuactive, setactive] = useState(false);
  const arr = ["/traininglesson", "/quiz", "/quizretake"];
  const getlocation = window.location.pathname;
  const isShow = arr.includes(getlocation);
  const [show, setShow] = useState(false);
  const [menuopen, setmenu] = useState(false);
  const [name, setName] = useState("");
  const [permissions, setPermissions] = useState({});
  const [role, setRole] = useState({});
  const [isRerender, setIsRerender] = useState(false);

  // useEffect(() => {
  //   fetchName();
  // }, []);

  useEffect(() => {
    fetchName();
  }, [isRerenderHeader, isRerender]);

  const fetchName = async () => {
    try {
      const { value } = await props.user();
      if (value?.success) {
        if (
          value?.user?.role === "operator" ||
          value?.user?.role === "manager"
        ) {
          setName(value);
          setRole(value?.user?.roleId);
          setPermissions(value?.user?.roleId?.permissions);
        } else {
          navigate("/login");
        }
      } else {
        if (value?.message === "Authentication token expired | Unauthorized") {
          navigate("/login");
        }
        dispatch(setAlert("error", value?.message));
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message));
    }
  };

  let bodyclick = "";

  const openmenu = () => {
    setmenu(menuopen ? false : true);
    myFunction();
  };

  function myFunction() {
    var element = document.getElementById("profile-wrapper");
    element.classList.toggle("left-wrapper");
  }

  const handleShow = () => {
    setShow(true);
  };

  useEffect(() => {
    const checkAuth = localStorage.getItem("token");
    if (checkAuth === null) {
      window.location.pathname = "/login";
    }

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
        navigator.userAgent
      )
    ) {
      setmenu(true);
    }
    var a = document.querySelectorAll(".navbar-nav a");
    for (var i = 0, length = a.length; i < length; i++) {
      a[i].onclick = function () {
        var b = document.querySelector(".navbar-nav li.active");
        // if (b) b.classList.remove("active");
        // this.parentNode.classList.add("active");
      };
    }
  }, [menuactive]);

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("superAdminToken");
    localStorage.removeItem("operatorToken");
  };

  const REFUND_REQUEST_LINK = (
    <li className={pathname === "requests" ? "nav-item active" : "nav-item"}>
      <Link className="nav-link " to="/requests">
        Refund/Void Requests
      </Link>
    </li>
  );

  const handelBackToSuperAdmin = (superAdminToken) => {
    try {
      dispatch(setLoading(true));
      localStorage.setItem("token", superAdminToken);
      localStorage.removeItem("superAdminToken");
      navigate("/admin");
      setIsRerender((prev) => !prev);
    } catch (error) {
      dispatch(setAlert("error", error?.message));
    }
    dispatch(setLoading(false));
  };
  const handelBackTOperator = (operatorToken) => {
    try {
      dispatch(setLoading(true));
      localStorage.setItem("token", operatorToken);
      localStorage.removeItem("operatorToken");
      navigate("/managers");
      setIsRerender((prev) => !prev);
    } catch (error) {
      dispatch(setAlert("error", error?.message));
    }
    dispatch(setLoading(false));
  };

  return (
    <>
      <header className="Header">
        <nav className="navbar  navbar-dark">
          <div className="container-fluid p-0">
            <div
              className={
                menuopen
                  ? "primary-menu-container "
                  : "primary-menu-container showmenu"
              }
              onClick={openmenu}
            >
              <div
                className="sidebar-container"
                onClick={(e) => e.stopPropagation()}
              >
                <ul className="navbar-nav me-auto">
                  {(permissions?.Dashboard_view ||
                    name?.user?.role === "operator") && (
                    <li
                      className={
                        pathname === "dashboard"
                          ? "nav-item active"
                          : "nav-item"
                      }
                      id="dashboard"
                    >
                      <Link className="nav-link " to="/dashboard">
                        Dashboard
                      </Link>
                    </li>
                  )}
                  {(name?.user?.role === "operator" ||
                    permissions?.Lots_view) && (
                    <li
                      className={
                        pathname === "lots" ? "nav-item active" : "nav-item"
                      }
                    >
                      <Link className="nav-link " to="/lots">
                        Your Lots
                      </Link>
                    </li>
                  )}
                  {(name?.user?.role === "operator" ||
                    permissions?.Report_view) && (
                    <li
                      className={
                        pathname === "report" ? "nav-item active" : "nav-item"
                      }
                    >
                      <Link className="nav-link" to="/report">
                        Reports
                      </Link>
                    </li>
                  )}
                  {(name?.user?.role === "operator" ||
                    permissions?.TagsDeployed_view) && (
                    <li
                      className={
                        pathname === "operatorcitations"
                          ? "nav-item active"
                          : "nav-item"
                      }
                    >
                      <Link className="nav-link " to="/operatorcitations">
                        Tags Deployed
                      </Link>
                    </li>
                  )}
                  {
                  <li className={pathname === "operator-payout" ? "nav-item active" : "nav-item"}>
                    <Link className="nav-link" to="/operator-payout">
                      Payouts
                    </Link>
                  </li>
                  }
                  {role?.title?.toLowerCase() === "operator" ||
                    (name?.user?.role === "operator" && (
                      <>
                        <li
                          className={
                            pathname === "connectWithStripe"
                              ? "nav-item active"
                              : "nav-item"
                          }
                        >
                          <Link className="nav-link" to="/connectWithStripe">
                            Connect with Stripe
                          </Link>
                        </li>
                      </>
                    ))}
                  {(name?.user?.role === "operator" ||
                    permissions?.Permits_view) && (
                    <li
                      className={
                        pathname === "permit" ? "nav-item active" : "nav-item"
                      }
                    >
                      <Link className="nav-link" to="/permit">
                        Permits
                      </Link>
                    </li>
                  )}
                  {(name?.user?.role === "operator" ||
                    permissions?.Notice_view) && (
                    <li
                      className={
                        pathname === "offence" ? "nav-item active" : "nav-item"
                      }
                    >
                      <Link className="nav-link" to="/offence">
                        Notices
                      </Link>
                    </li>
                  )}
                  {(name?.user?.role === "operator" ||
                    permissions?.DisputedCitations_view) && (
                    <li
                      className={
                        pathname === "operatorDisputedCitations"
                          ? "nav-item active"
                          : "nav-item"
                      }
                    >
                      <Link
                        className="nav-link"
                        to="/operatorDisputedCitations"
                      >
                        Disputed Citations
                      </Link>
                    </li>
                  )}
                  <li
                    className={
                      pathname === "support" ? "nav-item active" : "nav-item"
                    }
                  >
                    <Link
                      className="nav-link"
                      to="/support"
                      onClick={handleShow}
                    >
                      Contact Support
                    </Link>
                  </li>
                  {(name?.user?.role === "operator" ||
                    permissions?.Visits_view) && (
                    <li
                      className={
                        pathname === "taggr_activities"
                          ? "nav-item active"
                          : "nav-item"
                      }
                    >
                      <Link className="nav-link" to="/taggr_activities">
                        Visits
                      </Link>
                    </li>
                  )}
                  {(role?.title?.toLowerCase() === "operator" ||
                    name?.user?.role === "operator") && (
                    <>
                      <li
                        className={
                          pathname === "role" ? "nav-item active" : "nav-item"
                        }
                      >
                        <Link className="nav-link" to="/role">
                          Roles
                        </Link>
                      </li>
                      <li
                        className={
                          pathname === "managers"
                            ? "nav-item active"
                            : "nav-item"
                        }
                      >
                        <Link
                          className="nav-link"
                          to="/managers"
                          onClick={handleShow}
                        >
                          Create Users
                        </Link>
                      </li>
                    </>
                  )}
                  {(name?.user?.role === "operator" ||
                    permissions?.Refund_view) &&
                    REFUND_REQUEST_LINK}
                  {localStorage.getItem("superAdminToken") &&
                  localStorage.getItem("superAdminToken") !== "" ? (
                    <>
                      <li
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          className="nav-link"
                          onClick={() => {
                            handelBackToSuperAdmin(
                              localStorage.getItem("superAdminToken")
                            );
                          }}
                        >
                          Back to Super Admin
                        </Button>
                      </li>
                    </>
                  ) : null}

                  {localStorage.getItem("operatorToken") &&
                  localStorage.getItem("operatorToken") !== "" ? (
                    <>
                      <li
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          className="nav-link"
                          onClick={() => {
                            handelBackTOperator(
                              localStorage.getItem("operatorToken")
                            );
                          }}
                        >
                          Back to Operator
                        </Button>
                      </li>
                    </>
                  ) : null}
                </ul>
              </div>
            </div>
            <div className="MenuWidth">
              <button
                id="menu--btn"
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#mynavbar"
                onClick={openmenu}
              >
                <img src={icMenu} alt="" />
              </button>
            </div>

            <div className="dropdown">
              <button
                type="button"
                className="btn btn-primary dropdown-toggle"
                data-bs-toggle={!isShow ? "dropdown" : ""}
              >
                <div>
                  <img
                    src={name?.user?.avatar}
                    alt=""
                    className="ProfileImage"
                  />
                </div>
                <div className="dropdown-text">
                  {/* <h5>Tagger Name</h5> */}
                  <h5>{name?.user?.fullname}</h5>
                  <h6>{name?.user?.email}</h6>
                </div>
                {!isShow && <span className="dropdown-toggle-arrow"></span>}
              </button>
              <ul className="dropdown-menu">
                <li>
                  <Link to="/profile" className="dropdown-item">
                    Profile
                    <img src={profileIcon} alt="" />
                  </Link>
                </li>
                <li>
                  <Link to="/change_password" className="dropdown-item">
                    Change Password
                    <img src={passsword} alt="" />
                  </Link>
                </li>
                <li>
                  <Link onClick={logout} to="/login" className="dropdown-item">
                    Logout <img src={logoutIcon} alt="" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <Link className="navbar-brand" to="/dashboard">
            {" "}
            <img src={brandLogo} className="BrandLogo" alt="taggr logo" />
          </Link>
        </nav>
      </header>
    </>
  );
};
export default Header;
